import ALL_TAX_RATES from '@/graphql/tax-rates/query/getAllTaxRatesAutocomplete.gql'

const taxRates = ref([])
const key = ref(0)
const firstTime = ref(true)

export async function taxRatesAutocomplete(params: any) {
  const apolloQuery = ALL_TAX_RATES
  const variables = {
    search: params.search ? params.search : null,
    orderBy: [
      {
        column: 'NAME',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 10,
    page: 1
  }

  if (!variables.search) {
    delete variables.search
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchTaxRatesCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await taxRatesAutocomplete({ search: queryString })
    cb(results?.getAllTaxRates?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchTaxRatesCb')
  }
}

export function getSearchTaxRates(entityTaxRate: any, entityTaxRates: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchTaxRates('', entityTaxRate, entityTaxRates)
  }
  return taxRates?.value ?? []
}

export async function searchTaxRates(queryString: any, entityTaxRate: any, entityTaxRates: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await taxRatesAutocomplete({ search: queryString })
    const remoteTaxRates = results?.getAllTaxRates?.data?.map((i: any) => {
      return {
        label: `${i.name} - ${(i.rate / 100).toString()}`,
        value: i.uuid
      }
    }) ?? []
    const taxRatesToAdd: { label: any; value: any }[] = []
    entityTaxRates?.forEach((j: any) => {
      if (!remoteTaxRates.find((k: any) => k.value === j.uuid)) {
        taxRatesToAdd.push({
          label: `${j.name} - ${(j.rate / 100).toString()}`,
          value: j.uuid
        })
      }
    })
    if (entityTaxRate && !remoteTaxRates.find((k: any) => k.value === entityTaxRate.uuid)) {
      taxRatesToAdd.push({
        label: `${entityTaxRate.name} - ${(entityTaxRate.rate / 100).toString()}`,
        value: entityTaxRate.uuid
      })
    }
    if (refresh) {
      key.value = 0
    }
    if (key.value === 0) {
      key.value = setCustomKeyVariable()
    }
    taxRates.value = [...remoteTaxRates, ...taxRatesToAdd]
    firstTime.value = false
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchTaxRates')
  }
}

export function getTaxRateKey () {
  return key.value
}

export function initialTaxRates () {
  key.value = 0
  taxRates.value = []
  firstTime.value = true
}